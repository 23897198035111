import dynamic from "next/dynamic";

const componentList = {
    Consultdrawing:           dynamic(() => import(`components/components/ConsultDrawing.jsx`)),
    AccordionList:            dynamic(() => import(`components/components/accordion-list.jsx`)),
    Accordion:                dynamic(() => import(`components/components/accordion.jsx`)),
    ActionGatedContent:       dynamic(() => import(`components/components/action-gated-content.jsx`)),
    Action:                   dynamic(() => import(`components/components/action.jsx`)),
    AddOnCards:               dynamic(() => import(`components/components/add-on-cards.jsx`)),
    ArticleCard:              dynamic(() => import(`components/components/article-card.jsx`)),
    AskQuestion:              dynamic(() => import(`components/components/ask-question.jsx`)),
    BookACallModal:           dynamic(() => import(`components/components/book-a-call-modal.jsx`)),
    BookACall:                dynamic(() => import(`components/components/book-a-call.jsx`)),
    Breadcrumb:               dynamic(() => import(`components/components/breadcrumb.jsx`)),
    Brochure:                 dynamic(() => import(`components/components/brochure.jsx`)),
    CalculatorPayg:           dynamic(() => import(`components/components/calculator-payg.jsx`)),
    CallUs:                   dynamic(() => import(`components/components/call-us.jsx`)),
    CampaignNav:              dynamic(() => import(`components/components/campaign-nav.jsx`)),
    Captcha:                  dynamic(() => import(`components/components/captcha.tsx`)),
    Card:                     dynamic(() => import(`components/components/card.jsx`)),
    CardsCarousel:            dynamic(() => import(`components/components/cards-carousel.jsx`)),
    CareersForm:              dynamic(() => import(`components/components/careers-form.jsx`)),
    CarouselProgressControls: dynamic(() => import(`components/components/carousel-progress-controls.tsx`)),
    CarouselProgress:         dynamic(() => import(`components/components/carousel-progress.tsx`)),
    Carousel:                 dynamic(() => import(`components/components/carousel.jsx`)),
    Chapters:                 dynamic(() => import(`components/components/chapters.jsx`)),
    Clipboard:                dynamic(() => import(`components/components/clipboard.jsx`)),
    ColoredCard:              dynamic(() => import(`components/components/colored-card.jsx`)),
    ColoredCards:             dynamic(() => import(`components/components/colored-cards.jsx`)),
    CommonQuestions:          dynamic(() => import(`components/components/common-questions.jsx`)),
    ConsultSendForm:          dynamic(() => import(`components/components/consult-send-form.jsx`)),
    ContactForm:              dynamic(() => import(`components/components/contact-form.jsx`)),
    ContentList:              dynamic(() => import(`components/components/content-list.jsx`)),
    CookieConsent:            dynamic(() => import(`components/components/cookie-consent.jsx`)),
    CountUp:                  dynamic(() => import(`components/components/count-up.jsx`)),
    CustomerStoryCard:        dynamic(() => import(`components/components/customer-story-card.jsx`)),
    EbookCopy:                dynamic(() => import(`components/components/ebook-copy.jsx`)),
    Endorsement:              dynamic(() => import(`components/components/endorsement.jsx`)),
    ExpressionOfInterest:     dynamic(() => import(`components/components/expression-of-interest.jsx`)),
    FeaturedPostsCarousel:    dynamic(() => import(`components/components/featured-posts-carousel.jsx`)),
    FeaturesBucket:           dynamic(() => import(`components/components/features-bucket.jsx`)),
    FeaturesComparison:       dynamic(() => import(`components/components/features-comparison.jsx`)),
    FeaturesList:             dynamic(() => import(`components/components/features-list.jsx`)),
    FlipCards:                dynamic(() => import(`components/components/flip-cards.tsx`)),
    Footer:                   dynamic(() => import(`components/components/footer.jsx`)),
    GatedContent:             dynamic(() => import(`components/components/gated-content.tsx`)),
    GutenPersonalisation:     dynamic(() => import(`components/components/guten-personalisation.jsx`)),
    HardwareCountrySelector:  dynamic(() => import(`components/components/hardware-country-selector.jsx`)),
    HardwareRecommended:      dynamic(() => import(`components/components/hardware-recommended.jsx`)),
    Hardware:                 dynamic(() => import(`components/components/hardware.jsx`)),
    HeapCustomEvent:          dynamic(() => import(`components/components/heap-custom-event.tsx`)),
    HeroBannerOld:            dynamic(() => import(`components/components/hero-banner-old.jsx`)),
    HeroBannerV2:             dynamic(() => import(`components/components/hero-banner-v2.jsx`)),
    HeroBanner:               dynamic(() => import(`components/components/hero-banner.jsx`)),
    HeroBlockLatest:          dynamic(() => import(`components/components/hero-block-latest.tsx`)),
    HeroBlock:                dynamic(() => import(`components/components/hero-block.tsx`)),
    HeroCarousel:             dynamic(() => import(`components/components/hero-carousel.tsx`)),
    HeroCentred:              dynamic(() => import(`components/components/hero-centred.tsx`)),
    HeroGatedVideo:           dynamic(() => import(`components/components/hero-gated-video.jsx`)),
    HeroLeadCapture:          dynamic(() => import(`components/components/hero-lead-capture.tsx`)),
    HeroRotatingText:         dynamic(() => import(`components/components/hero-rotating-text.tsx`)),
    HeroSmsBanner:            dynamic(() => import(`components/components/hero-sms-banner.jsx`)),
    HeroStack:                dynamic(() => import(`components/components/hero-stack.jsx`)),
    HubHeader:                dynamic(() => import(`components/components/hub-header.jsx`)),
    HubSubscribe:             dynamic(() => import(`components/components/hub-subscribe.jsx`)),
    IconLinks:                dynamic(() => import(`components/components/icon-links.tsx`)),
    IconListDynamic:          dynamic(() => import(`components/components/icon-list-dynamic.tsx`)),
    ImageCards:               dynamic(() => import(`components/components/image-cards.jsx`)),
    ImageCloud:               dynamic(() => import(`components/components/image-cloud.jsx`)),
    Image:                    dynamic(() => import(`components/components/image.tsx`)),
    IndustryTiles:            dynamic(() => import(`components/components/industry-tiles.tsx`)),
    InlineImages:             dynamic(() => import(`components/components/inline-images.jsx`)),
    LeadCap:                  dynamic(() => import(`components/components/lead-cap.jsx`)),
    LeadForm:                 dynamic(() => import(`components/components/lead-form.jsx`)),
    LeadingText:              dynamic(() => import(`components/components/leading-text.jsx`)),
    LoginScreen:              dynamic(() => import(`components/components/login-screen.jsx`)),
    MarketoFormBlock:         dynamic(() => import(`components/components/marketo-form-block.tsx`)),
    MarketoForm:              dynamic(() => import(`components/components/marketo-form.tsx`)),
    NavWrapper:               dynamic(() => import(`components/components/nav-wrapper.jsx`)),
    NotificationBar:          dynamic(() => import(`components/components/notification-bar.jsx`)),
    Overlay:                  dynamic(() => import(`components/components/overlay.jsx`)),
    ParallaxContent:          dynamic(() => import(`components/components/parallax-content.tsx`)),
    Parallax:                 dynamic(() => import(`components/components/parallax.tsx`)),
    PathwayCards:             dynamic(() => import(`components/components/pathway-cards.tsx`)),
    Pathways:                 dynamic(() => import(`components/components/pathways.tsx`)),
    Personalisation:          dynamic(() => import(`components/components/personalisation.jsx`)),
    PlanCalculatorDetail:     dynamic(() => import(`components/components/plan-calculator-detail.jsx`)),
    PlanCalculator:           dynamic(() => import(`components/components/plan-calculator.jsx`)),
    PopUp:                    dynamic(() => import(`components/components/pop-up.tsx`)),
    PostsInStickyList:        dynamic(() => import(`components/components/posts-in-sticky-list.jsx`)),
    PricingAccordionOld:      dynamic(() => import(`components/components/pricing-accordion-old.jsx`)),
    PricingAccordion:         dynamic(() => import(`components/components/pricing-accordion.jsx`)),
    PricingCalculator:        dynamic(() => import(`components/components/pricing-calculator.jsx`)),
    PricingCards:             dynamic(() => import(`components/components/pricing-cards.tsx`)),
    PricingComparisonOld:     dynamic(() => import(`components/components/pricing-comparison-old.jsx`)),
    PricingComparison:        dynamic(() => import(`components/components/pricing-comparison.jsx`)),
    PricingCountrySelector:   dynamic(() => import(`components/components/pricing-country-selector.jsx`)),
    PricingDescription:       dynamic(() => import(`components/components/pricing-description.jsx`)),
    PricingList:              dynamic(() => import(`components/components/pricing-list.jsx`)),
    PricingPage:              dynamic(() => import(`components/components/pricing-page.tsx`)),
    PricingPlan:              dynamic(() => import(`components/components/pricing-plan.jsx`)),
    PromoBanner:              dynamic(() => import(`components/components/promo-banner.tsx`)),
    QuoteCarousel:            dynamic(() => import(`components/components/quote-carousel.jsx`)),
    Quote:                    dynamic(() => import(`components/components/quote.jsx`)),
    QuotesAnimated:           dynamic(() => import(`components/components/quotes-animated.tsx`)),
    ReferAFriend:             dynamic(() => import(`components/components/refer-a-friend.jsx`)),
    ReferUs:                  dynamic(() => import(`components/components/refer-us.jsx`)),
    RegisterInterest:         dynamic(() => import(`components/components/register-interest.jsx`)),
    Register:                 dynamic(() => import(`components/components/register.jsx`)),
    RenderContent:            dynamic(() => import(`components/components/render-content.jsx`)),
    ResponsiveSpacing:        dynamic(() => import(`components/components/responsive-spacing.jsx`)),
    ScrollAccordion:          dynamic(() => import(`components/components/scroll-accordion.tsx`)),
    Search:                   dynamic(() => import(`components/components/search.jsx`)),
    SendConsult:              dynamic(() => import(`components/components/send-consult.jsx`)),
    SignUpPaged:              dynamic(() => import(`components/components/sign-up-paged.tsx`)),
    SignUp:                   dynamic(() => import(`components/components/sign-up.tsx`)),
    Sign_up_landing_page:     dynamic(() => import(`components/components/sign_up_landing_page.tsx`)),
    SliderMobile:             dynamic(() => import(`components/components/slider-mobile.tsx`)),
    SocialSharing:            dynamic(() => import(`components/components/social-sharing.jsx`)),
    StandardContent:          dynamic(() => import(`components/components/standard-content.jsx`)),
    StickySection:            dynamic(() => import(`components/components/sticky-section.jsx`)),
    StyledHeader:             dynamic(() => import(`components/components/styled-header.jsx`)),
    TableOfContents:          dynamic(() => import(`components/components/table-of-contents.jsx`)),
    TalkToUs:                 dynamic(() => import(`components/components/talk-to-us.jsx`)),
    TeamSizeChooser:          dynamic(() => import(`components/components/team-size-chooser.jsx`)),
    TestimonialCarousel:      dynamic(() => import(`components/components/testimonial-carousel.tsx`)),
    TickListBlock:            dynamic(() => import(`components/components/tick-list-block.tsx`)),
    TimelyButtons:            dynamic(() => import(`components/components/timely-buttons.tsx`)),
    TimelyNav:                dynamic(() => import(`components/components/timely-nav.jsx`)),
    TwoColumn:                dynamic(() => import(`components/components/two-column.jsx`)),
    VideoCourseCta:           dynamic(() => import(`components/components/video-course-cta.tsx`)),
    VideoCourseHero:          dynamic(() => import(`components/components/video-course-hero.tsx`)),
    VideoCourseIntroVideo:    dynamic(() => import(`components/components/video-course-intro-video.tsx`)),
    VideoCourseNext:          dynamic(() => import(`components/components/video-course-next.tsx`)),
    VideoCoursePillar:        dynamic(() => import(`components/components/video-course-pillar.tsx`)),
    VideoQuote:               dynamic(() => import(`components/components/video-quote.jsx`)),
    WebinarRegister:          dynamic(() => import(`components/components/webinar-register.jsx`)),
    WebinarSignup:            dynamic(() => import(`components/components/webinar-signup.jsx`)),
};

export default componentList;
