import React from "react";
import HtmlToReact, { Parser } from "html-to-react";
import componentList from "../components/utils/component-list";
import { makeComponentName } from "../components/utils/component-name";

export default function RenderContent({ structure, components }) {
  const htmlParser = new Parser(React);
  const processNodeDefinitions = new HtmlToReact.ProcessNodeDefinitions(React);
  function isValidNode() {
    return true;
  }

  // Custom instructions for processing nodes
  const processingInstructions = [
    // Create instruction for custom elements

    {
      replaceChildren: true,

      shouldProcessNode: (node) => {
        let shouldProcess = false;

        // Process the node if it matches a custom element
        components.find((comp) => {
          if (
            comp &&
            node.attribs &&
            node.attribs.id &&
            comp.id &&
            node.attribs.id == comp.id
          ) {
            shouldProcess = true;
          }
        });
        return shouldProcess;
      },

      processNode: (node) => {
        const comp = components.find((c) => c.id && c.id == node.attribs.id);
        const CustomElement = componentList[makeComponentName(comp.type)];
        return <CustomElement displayName={comp.type} key={comp.id} block={comp} />;
      },
    },
    // Default processing
    {
      // Anything else
      shouldProcessNode: function (node) {
        if (node.data === ",") {
          return false;
        }
        return true;
      },
      // prettier-ignore
      processNode: processNodeDefinitions.processDefaultNode,
    },
  ];
  return htmlParser.parseWithInstructions(
    structure,
    isValidNode,
    processingInstructions
  );
}
